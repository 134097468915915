import React from 'react';

function Footer() {
    return (
        <div className="FooterWrapper">
            <p>Copyright © 2021 Jimmy Hwang</p>
        </div>
    )
}

export default Footer;